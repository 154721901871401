@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


:focus-visible {
  outline: none !important;
}



.full_chatbot {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

/* width */
.full_chatbot ::-webkit-scrollbar {
  width: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}


/* Track */
.full_chatbot ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle */
.full_chatbot ::-webkit-scrollbar-thumb {
  background: #3c6e95;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.chat_radcliffe.full_chatbot ::-webkit-scrollbar-thumb {
  background: #143557;
}

/* Handle on hover */
.full_chatbot ::-webkit-scrollbar-thumb:hover {
  background: #325e80;
}
.chat_radcliffe.full_chatbot ::-webkit-scrollbar-thumb:hover {
  background: #143557;
}


.full_chatbot .iconset .crossico {
  font-size: 32px;
  background: #3C6E95;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}
.chat_radcliffe.full_chatbot ::-webkit-scrollbar-thumb:hover {
  background: #143557;
}

.full_chatbot .iconset .showicon {
  font-size: 32px;
  background: #3C6E95;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 15;
  position: relative;
  cursor: pointer;
}
.chat_radcliffe.full_chatbot .iconset .showicon {
  background: #143557;
}

.full_chatbot .all_chatpart_design {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #3c6e95;
  width: 100%;
  max-width: 350px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.chat_radcliffe.full_chatbot .all_chatpart_design {
  background: #143557;
}


.full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background: #fff;
  padding: 1px;
  border-radius: 50%;
}

.full_chatbot .all_chatpart_design .all_chatpart_header {
  background: #3c6e95;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  font-family: "Inter", sans-serif;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  gap: 20px;
  padding: 13px 16px 1px;
  justify-content: space-between;
  align-items: start;
}

.chat_radcliffe.full_chatbot .all_chatpart_design .all_chatpart_header {
  background: #143557;
}

.full_chatbot .all_chatpart_design .all_chatpart_header .with_avtar {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 15px;
}

.full_chatbot .all_chatpart_design .all_chatpart_header .donw_arrow {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.full_chatbot .all_chatpart_design .all_chatpart_header .donw_arrow img {
  width: 30px;
  height: 30px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body {
  padding: 0 15px;
  height: calc(90vh - 310px);
  max-height: 420px;
  overflow: auto;
  background: #E9EBEC;
  padding-top: 20px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box {
  width: fit-content;
  max-width: calc(100% - 30px);
  min-width: calc(20px);
  background: #fff;
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  margin-bottom: 10px;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #464749;
  border: 1.5px solid #4e799b;

}
.chat_radcliffe.full_chatbot .all_chatpart_design .all_chatpart_body .box {
  border: 1.5px solid #143557;

}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root {
  width: 100%;
  margin-bottom: 10px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl_root label.MuiFormLabel_root {
  line-height: 11px;
  font-size: 12px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root input {
  height: auto;
  padding: 9px;
  width: 100%;
  background: #fff;
  font-size: 12px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.MuiFormLabel-root.MuiFormLabel-filled,
.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root label.Mui-focused {
  top: 5px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root .MuiSelect-select {
  font-size: 13px;
  padding: 9px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .MuiFormControl-root {
  background: #fff;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box.right_side {
  margin-left: auto;
  background: #3c6e95;
  color: #fff;
  border: 1.5px solid #3c6e95;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
}
.chat_radcliffe.full_chatbot .all_chatpart_design .all_chatpart_body .box.right_side {
  background: #2BC0F4;
  border: 1.5px solid #2BC0F4;
}

.close_text {
  font-size: 13px;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer {
  padding: 8px 12px;
  display: flex;
  margin-bottom: 0 !important;
  align-items: center;
  border-top: 1px solid #0000002b;
  background: #3c6e95;
  border-radius: 30px;
}
.chat_radcliffe.full_chatbot .all_chatpart_design .all_chatpart_footer {
  background: #143557;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer img {
  width: 20px;
  object-fit: contain;
  margin-right: 5px;
}

.chatbot_pill {
  background: #E9EBEC;
  padding: 10px;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"] {
  width: 100%;
  border: none;
  outline: none;
  padding: 2px;
  font-size: 15px;
  background-color: #3c6e95;
  border-radius: 30px;
  color: #fff;
}
.chat_radcliffe.full_chatbot .all_chatpart_design .all_chatpart_footer input[type="text"] {
  background-color: #143557;
}


input[type="text"]::placeholder {
  color: #fff !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_footer div {
  color: #fff;
  font-weight: bold;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 0px !important;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box .checkbox_chat {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn {
  font-size: 13px;
  background: blue;
  border: 0;
  color: #fff;
  padding: 8px 15px;
  margin-left: auto;
  margin-bottom: 4px;
  display: block;
  border-radius: 4px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:hover,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:active,
.full_chatbot .all_chatpart_design .all_chatpart_body .box button.btn:focus {
  background: #5d5dff;
}

.agent_icon {
  position: relative;
}
.online_dot {
  width: 15px;
  height: 15px;
  display: inline-flex;
  background: linear-gradient(45deg, #2ECC71, #6de5a0);
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  right: -5px;
}

.more_Option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.online_pos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
}

.we_online_box {
  width: auto;
  height: 21px;
  background: #ffffff;
  margin-top: -25px !important;
  display: inline-flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #343333;
  font-weight: 600;
  font-size: 12px;
  padding: 0 15px;
  border-radius: 20px;
  z-index: 99;
  box-shadow: 1px 1px #ddd;
  margin-top: -20px !important;
}

.we_online_box img {
  width: 9px;
  object-fit: contain;
  margin-right: 4px;
}

.powered_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background: #b7d1e4;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 11px;
  padding: 0 10px;
  color: #5f6062;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

}
.chat_radcliffe.full_chatbot .powered_box {
  background: #a4dff4;
}

.powered_box img {
  width: 25px;
  object-fit: contain;
  margin: 4px;
}

.powered_box span {
  font-weight: 600;
  color: #040404;
}

.send_icon {
  height: fit-content;
  width: 20px;
  object-fit: contain;
  margin-left: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.sender_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-style: italic;
  color: #315d80;
}

.chat_radcliffe.full_chatbot .sender_box {
  color: #143557;
}


.sender_box img {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-right: 5px;
  border-radius: 50%;
}

.sender_right_side {
  justify-content: flex-end;
  margin-right: 5px;
}

.sender_box span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 0.5px solid #0000001a;
  color: #7c7e82;
  font-size: 10px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  line-height: 0;
  justify-content: center;
  background: rgba(41, 41, 41, 0.1);
}

.calanedar_container {
  max-width: 95%;
  width: 95%;
  /* max-height: 250px; */
  box-shadow: 0px 0px 12.100000381469727px 0px #0000001c;
  margin: 10px;
  margin-bottom: 20px;
}

.slectedChip>div {
  background: #3c6e95;
  color: #fff;
  border: 1.5px solid #3c6e95;
  border-radius: 15px;

}

.chat_radcliffe.full_chatbot .slectedChip>div {
  background: #2BC0F4;
  border: 1.5px solid #2BC0F4;
}

.sender_chip>div {
  background-color: #a2a2a2;
  border: 1.5px solid #a2a2a2;
  border-radius: 15px;
  color: #fff;
  font-size: 12px;

}

.sender_chip>div:hover {
  background-color: #3c6e95 !important;
  color: #fff;
  border-color: #3c6e95;

}

.chat_radcliffe.full_chatbot .sender_chip>div:hover {
  background-color: #2BC0F4 !important;
  border-color: #2BC0F4;
}

.place_card2 {
  position: absolute;
  z-index: 10000;
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  width: 91.5%;
  bottom: 75px;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
}

.placeList {
  padding: 0.25rem 0.25rem;
  display: flex;
  border-bottom: 1px solid #ddd;
  color: #343333 !important;
}

.placeList_active {
  padding: 0.25rem 0.25rem;
  display: flex;
  border-bottom: 1px solid #ddd;
  background: #3C6E95;
  color: white !important;
}
.chat_radcliffe.full_chatbot .placeList_active {
  background: #143557;
}

.placeList svg {
  font-size: 20px;
  color: #ddd;
  margin-top: 2px;
  margin-right: 2px;
}

.placeList:hover svg {
  color: #fff;
}

.placeList {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: #343333;
  padding: 10px !important;
  border-radius: 0px;
}

.placeList:hover {
  background: #3c6e95;
  color: #fff !important;
}

.chat_radcliffe.full_chatbot .placeList:hover {
  background: #143557;
}


.placeList_active {
  width: 100%;
  font-size: 12px;
  margin: 7px;
  color: white;
  background-color: #933eff;
}
.chat_radcliffe.full_chatbot .placeList_active {
  background-color: #143557;
}

.address_input>div>fieldset {
  border: none;
}

.address_input>div {
  padding: 0px !important;
  border: none;
}

.address_input>div>input {
  color: #464749;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.address_input>div>div {
  display: none;
  padding: 0px;
}

.chat_bubble {
  background-color: #f0f2f7;
  padding: 10px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #8cb2cf;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #8cb2cf;
  }

  28% {
    transform: translateY(-7px);
    background-color: #8cb2cf;
  }

  44% {
    transform: translateY(0px);
    background-color: #8cb2cf;
  }
}

/* ankush css */

.chatfloat_icon {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #3C6E95;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;
  position: relative;
}

.chat_radcliffe.full_chatbot .chatfloat_icon {
  background: #143557;
}

.agent_icon img {
  width: 36px;
  height: auto;
}

.agent_chatline {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}

.agent_icon_collapsed img {
  width: 40px;
  height: auto;
  border: 2px solid #3c6e95;
  border-radius: 50%;
  cursor: pointer;

}

.chat_radcliffe.full_chatbot .agent_icon_collapsed img {
  border: 2px solid #143557;
}

.arrow_wrapper {
  position: absolute;
  bottom: 30px;
  right: -10px;
  animation: 2.5s infinite mover;
}

.chat_shape {
  position: relative;
  margin-bottom: -16px;

}

@keyframes mover {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }
}




@media screen and (max-width:577px) {
  .full_chatbot .all_chatpart_design {
    right: 5%;
    width: 100%;
    max-width: 90%;
    bottom: 70px;
  }
}

.conversation_close{
  color: #717070  ;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  font-family: 'Inter';
}
.chat_shape img {
    position: relative;
    z-index: 2;
}